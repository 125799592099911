@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira poppins, Droid poppins, Helvetica Neue,
    poppins-serif;
  -webkit-tap-highlight-color: transparent;
}

.qr-container {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

*:focus {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.running-emote {
  transform: scale(-1, 1);
  width: fit-content;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.blink-dot {
  animation: blinker 1s linear infinite;
}

/* width */
.scrollbar::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
  background: #f6f8fd;
  border-radius: 5px;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
  background: #3453dc;
  border-radius: 5px;
}

/* Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #98afff;
}

.scroller {
  overflow: auto;
  height: 100px;
  display: flex;
  flex-direction: column-reverse;
}

.scroller .scroller-content .item {
  height: 20px;
  transform: translateZ(
    0
  ); /* fixes a bug in Safari iOS where the scroller doesn't update */
}
